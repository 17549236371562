.container {
  img {
    width: 100%;
  }
  [class="RaRichTextInput-editorContent"] {
    overflow: auto;
    max-height: 800px;
  }
  iframe {
    width: 100vw;
    max-width: 1200px;
    height: 56.25vw;
    max-height: 680px;
  }
}
